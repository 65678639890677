import { useState } from "react";
import Reaptcha from "reaptcha";

import TelegramService from "../services/TelegramService";

const Development = ({ goTo }) => {
  return (
    <div
      className='bg-home-fabian d-flex flex-column justify-content-center align-items-center'
    >
      <div className='col d-flex flex-column justify-content-center align-items-center' style={{ paddingBottom: "4rem", marginBottom: "4rem", width: "90%", maxWidth: "800px" }}>
        <h1 style={{ color: "#008CFF", fontSize: "3rem", marginBottom: "2rem", textAlign: "center" }}>
          <span style={{ fontWeight: 700 }}>Nuestra</span> <span style={{ color: "black", fontWeight: 700 }}>Solución</span>
        </h1>

        <div className="card mb-4" style={{ width: "100%", backgroundColor: "#FF7F00", minHeight: "250px" }}>
          <h4 style={{ marginLeft: "10px", marginTop: "5px", color: "#ffff" }}>
            Gestión documental avanzada.
          </h4>
          <div className="card-body" style={{fontSize:"1.2rem"}}>
            <ul>
              <li style={{ color: "#ffff" }}>Plataforma robusta y fácil de usar para almacenar, organizar y gestionar todos sus documentos de manera eficiente.</li>
              <li style={{ color: "#ffff" }}>AvanzadaDesde la carga inicial de documentos hasta la clasificación y búsqueda rápida, nuestra solución se adapta a sus necesidades.</li>
            </ul>
          </div>
        </div>

        <div className="card mb-4" style={{ width: "100%", backgroundColor: "#008CFF", minHeight: "250px" }}>
          <h4 style={{ marginLeft: "10px", marginTop: "5px", color: "#ffff" }}>
            Seguridad y respaldo de su valiosa información
          </h4>
          <div className="card-body" style={{fontSize:"1.2rem"}}>
            <ul>
              <li style={{ color: "#ffff" }}>Garantizamos la confidencialidad y protección de su información sensible.</li>
              <li style={{ color: "#ffff" }}>Permisos de acceso personalizados para asegurar que solo las personas autorizadas puedan acceder a los documentos relevantes.</li>
            </ul>
          </div>
        </div>

        <div className="card mb-4" style={{ width: "100%", backgroundColor: "#EFBB1B", minHeight: "250px" }}>
          <h4 style={{ marginLeft: "10px", marginTop: "5px", color: "#ffff" }}>
            Reportes de cumplimiento y plazos para actualizar documentos
          </h4>
          <div className="card-body" style={{fontSize:"1.2rem"}}>
            <ul>
              <li style={{ color: "#ffff" }}>Manténgase al tanto de los vencimientos de documentos importantes.</li>
              <li style={{ color: "#ffff" }}>Nuestro sistema envía notificaciones y recordatorios automáticos para que pueda renovar y actualizar documentos a tiempo</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Development;
