import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';

import history from './history';

import Home from './pages/Home';
import Contact from './pages/Contact';
import DevelopmentPage from './pages/DevelopmentPage';
import AsyncAwaitBlogPage from './pages/Blog/AsyncAwaitBlogPage';
import StatePromisesBlogPage from './pages/Blog/StatePromisesBlogPage';
import DatesInJsBlogPage from './pages/Blog/DatesInJsBlogPage';
import JoinsMysqlBlogPage from './pages/Blog/JoinsMysqlBlogPage';
import HooksBlogPage from './pages/Blog/HooksBlogPage';
import PfxCertBlogPage from './pages/Blog/PfxCertBlogPage';
import CardinalidadesBlogPage from './pages/Blog/CardinalidadesBlogPage';
import ValidacionesVuestifyBlogPage from './pages/Blog/ValidacionesVuestifyBlogPage';
import AxiosFetchBlogPage from './pages/Blog/AxiosFetchBlogPage';
import QueryInjectionBlogPage from './pages/Blog/QueryInjectionBlogPage';
import CnameDnsBlogPage from './pages/Blog/CnameDnsBlogPage';
import CeremoniasScrumBlogPage from './pages/Blog/CeremoniasScrumBlogPage';
import GmailPhpBlogPage from './pages/Blog/GmailPhpBlogPage';
import FuntionesAlmacenadasBlogPage from './pages/Blog/FuntionesAlmacenadasBlogPage';
import RedesNeuronalesBlogPage from './pages/Blog/RedesNeuronalesBlogPage';
import TriggersSQLBlogPage from './pages/Blog/TriggersSQLBlogPage';
import CorsNodeBlogPage from './pages/Blog/CorsNodeBlogPage';
import ExploracionDatosBlogPage from './pages/Blog/ExploracionDatosBlogPage';
import DockerAppNodeBlogPage from './pages/Blog/DockerAppNodeBlogPage';
import MetodoHungaroBlogPage from './pages/Blog/MetodoHungaroBlogPage';
import FechasPhpBlogPage from './pages/Blog/FechasPhpBlogPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Redirect from="/*" to="/"/>
  </Switch>
);

ReactDOM.hydrate(
  <React.StrictMode>
    <Router history={history}>
     <div>
      <Routes/>
     </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

export {
  Routes
};

// serviceWorker.register();
