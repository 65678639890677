import React from 'react';

const Services = ({ scrollToRef, serviceRef, goTo }) => {
  return (
    <div ref={serviceRef}>
      <WhoWeAre />
      <CommonQuestions />
    </div>
  );
};

const WhoWeAre = () => {
  return (
    <div className="row p-4">
      <div className="col-12 col-lg-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          loading="lazy"
          src="/static/img/quienesSomos.png"
          alt="empresa-desarrollo-software-chile"
          title="SolutionOps SpA"
          className="img-fluid"
        />
      </div>
      <div className="col-12 col-lg-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign:"center" }}>
        <p style={{ color: 'black', fontWeight: 700, fontSize: '3.5rem' }}>
          ¿Quienes <span style={{ color: '#008CFF', fontWeight: 700 }}>somos</span>?
        </p>
        <p style={{ textAlign: 'justify', color: 'black', padding: '2rem', fontSize: '1.5rem' }}>
          En DocuGest entendemos los desafíos que enfrentan las empresas en cuanto a la gestión eficiente de su
          documentación. Nuestra misión es proporcionarles una solución confiable y segura para almacenar, disponibilizar y
          gestionar documentos de manera centralizada.
        </p>
      </div>
    </div>
  );
};

const CommonQuestions = () => {
  return (
    <div className="row mt-4 p-4" style={{ alignItems: 'center'}}>
      <div className="col-12" style={{ textAlign: 'center', marginBottom:"4rem" }}>
        <h1 style={{ color: '#008CFF', fontSize: '3.5rem' }}>
          <span style={{fontWeight: 700 }}>Problemas</span> <span style={{ color: 'black', fontWeight: 700 }}>frecuentes</span>
        </h1>
      </div>
      <div className="col-12 col-lg-6" style={{ color: 'black', fontSize: '1.5rem'}}>
        <div className="card mb-4" style={{ width: '80%', margin: '0 auto' }}>
          <div className="card-body d-flex align-items-center">
            <img
              loading="lazy"
              style={{width:"100px !important"}}
              src="/static/img/target.png"
              alt="empresa-desarrollo-software-chile"
              title="SolutionOps SpA"
              className="img-fluid mr-2 d-none d-lg-block"
            />
            <p>Desorden y dificultad para encontrar documentos importantes.</p>
          </div>
        </div>
        <div className="card mb-4" style={{ width: '80%', margin: '0 auto' }}>
          <div className="card-body d-flex align-items-center">
            <img
              loading="lazy"
              style={{width:"100px !important"}}
              src="/static/img/target.png"
              alt="empresa-desarrollo-software-chile"
              title="SolutionOps SpA"
              className="img-fluid mr-2 d-none d-lg-block"
            />
            <p>Pérdida de tiempo y productividad debido a la búsqueda manual de documentos.</p>
          </div>
        </div>
        <div className="card" style={{ width: '80%', margin: '0 auto' }}>
          <div className="card-body d-flex align-items-center">
            <img
              loading="lazy"
              style={{width:"100px !important"}}
              src="/static/img/target.png"
              alt="empresa-desarrollo-software-chile"
              title="SolutionOps SpA"
              className="img-fluid mr-2 d-none d-lg-block"
            />
            <p>Dificultad para mantener actualizados los documentos y cumplir con los plazos.</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          loading="lazy"
          src="/static/img/preguntasFecuentes.png"
          alt="empresa-desarrollo-software-chile"
          title="SolutionOps SpA"
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default Services;
