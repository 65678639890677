import { FaWhatsapp } from 'react-icons/fa';

const Footer = ({
  openWhatsapp
}) => {

  return (
    <footer style={{ paddingTop: "0" }}>
        <div className="container mt-4 footer_segment">
            <div className="row accordion" id="footer_cols">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 footer_1rst mb-30">
                    <p className="d-xl-block d-lg-block d-md-block">
                      <string style={{ fontSize:"18px"}}>CONTÁCTENOS</string>
                    </p>
                    <div >
                        <ul className="list-unstyled">
                          <li className="text-footer">
                            <a href="mailto:contacto@docugest.cl" width="151" height="48">contacto@docugest.cl</a>
                          </li>
                          <li className="text-footer">
                            <a href="#" width="110" height="48">Santiago de Chile</a>
                          </li>
                          <li className="text-footer">
                            <a href="tel:0056991981647">+56 9 9198 1647</a>
                          </li>
                        </ul>
                    </div>
                </div>

                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 footer_2rst mb-30">
                    <hr className="hr-grey d-md-none d-block"/>
                    <div className="space-block"></div>
                    <div>
                      <a href="https://dimasoft.cl" target="_blank">
                        <img
                          loading="lazy"
                          width="158"
                          height="90"
                          src="/static/img/dimasoft-partner.webp"
                          alt="dimasoft-partner-software-empresa"
                          title="SolutionOps SpA"
                          className="logo-dimasoft-partner img-fluid"
                          />
                      </a>
                    </div>
                </div> */}

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 footer_2rst mb-30">
                    <hr className="hr-grey d-md-none d-block"/>
                    <div className="space-block"></div>
                    <div>
                      <a href="/">
                        <img
                          loading="lazy"
                          width="180"
                          height="50"
                          src="/static/img/docugest-logo.png"
                          alt="empresa-desarrollo-software-chile"
                          title="SolutionOps SpA"
                          className="logo-footer-solops img-fluid"
                          />
                      </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyright" className="container-fluid px-0">
            <div className="separator-container py-3">
                <hr className="blue border-top-8px m-0"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="text-left-footer">© 2020-2023 Soluciones Operacionales y Técnicas SpA</p>
                    </div>
                </div>
            </div>
        </div>

        <a className="card-link btn-whatsapp-1 fixed-btn" name="btn-whatsapp-1" aria-label="Contacta a nuestro equipo" onClick={() => openWhatsapp()} href="#"><FaWhatsapp /></a>


    </footer>
  )
}

export default Footer;
