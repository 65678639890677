import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";

// AiOutlineWhatsApp

const Header = ({ scrollToRef, goTo, openWhatsapp, openCall }) => {
  return (
    <header className="main-header fixed-top scroll-friendly ">
      <div className="it-header-up-top clearfix">
        <div className="container">
          <div className="it-header-top-cta">
            <a
              className="a-tag-hover space-right"
              aria-label="facebook-solutionops"
              onClick={() => goTo("https://www.facebook.com/solutionops.cl/")}
              href="#"
            >
              <strong>
                <FaFacebook />
              </strong>
            </a>
            <a
              className="a-tag-hover space-right"
              aria-label="instagram-solutionops"
              onClick={() => goTo("https://www.instagram.com/solutionops.cl/")}
              href="#"
            >
              <strong>
                <FaInstagram />
              </strong>
            </a>
            <a className="a-tag-hover " onClick={() => openWhatsapp()} href="#">
              <strong>
                <FaWhatsapp />
              </strong>{" "}
              <span style={{ marginTop: "10px" }}>+56 9 9198 1647</span>{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <nav className="navbar navbar-expand-lg align-items-center">
          <a className="navbar-brand" href="/">
            <img
              loading="lazy"
              width="180"
              height="50"
              src="/static/img/docugest-logo.png"
              alt="empresa-desarrollo-software-chile"
              title="SolutionOps SpA"
              className="logo-solops img-fluid"
            />
          </a>
          <div className="d-xl-none d-lg-none d-flex">
            <button
              className="navbar-toggler"
              name="toogle"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <small>MENU</small>
              </span>
            </button>
          </div>
          <div className="col-xl-6 col-lg-4 d-xl-inline-block d-lg-inline-block d-none">
            &nbsp;
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav w-100 nav-justified justify-content-center align-items-center">
              <li className="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                <a className="nav-link" href="/">
                  <span className="hover-border-bottom-center">Inicio</span>
                </a>
              </li>
              {/* <li className="nav-item nav-countries dropdown d-xl-flex d-lg-flex h-100 align-middle justify-content-center align-items-center">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>Servicios</span>
                </a>
                <div className="dropdown-menu text-center">
                  <a
                    className="dropdown-item"
                    href="/servicios/desarrollo-de-software"
                  >
                    Desarrollo de Software
                  </a>
                </div>
              </li>

              <li className="nav-item d-flex h-100 align-middle justify-content-center align-items-center">
                <a
                  className="nav-link"
                  onClick={() => goTo("/contacto-web")}
                  href="javascript:;"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                >
                  <span className="hover-border-bottom-center">Contacto</span>
                </a>
              </li> */}

              {/* <li className="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center d-xl-none d-lg-none">
                          <a className="nav-link" href="https://demo.maxcommerce.cl?referredBy=solutionops.cl"><span className="hover-border-bottom-center">Demo E-commerce Restaurantes</span></a>
                        </li> */}
              {/* 
                        <li className="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center d-xl-none d-lg-none">
                          <a className="nav-link" href="https://demo.dimasoft.cl?referredBy=solutionops.cl"><span className="hover-border-bottom-center">Demo E-commerce - ERP</span></a>
                        </li> */}

              {/* <li className="nav-item nav-countries dropdown d-xl-flex d-lg-flex d-none h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/" role="button"
                               aria-haspopup="true" aria-expanded="false"><span>Demo</span></a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="https://demo.maxcommerce.cl?referredBy=solutionops.cl">E-commerce Restaurantes</a>
                                <a className="dropdown-item" href="https://demo.dimasoft.cl?referredBy=solutionops.cl">E-commerce - ERP</a>
                            </div>
                        </li> */}

              {/* <li className="nav-item d-flex h-100 align-middle justify-content-center align-items-center">
                            <a className="nav-link" onClick={()=>scrollToRef(contactRef)} data-toggle="collapse" data-target="#navbarNav"><span className="hover-border-bottom-center">Contacto</span></a>
                        </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
